// Modules
import React, { useEffect } from "react";
import { css } from "emotion";
import moment from 'moment'

// Styles
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import SilverwareForkKnifeIcon from "mdi-react/SilverwareForkKnifeIcon";
import TshirtCrewIcon from "mdi-react/TshirtCrewIcon";
import ShoeFormalIcon from "mdi-react/ShoeFormalIcon";
import HumanMaleIcon from "mdi-react/HumanMaleIcon";
import BagCheckedIcon from "mdi-react/BagCheckedIcon";
import AccountVoice from "mdi-react/RecordVoiceOverIcon";
import PauseCircleOutline from "mdi-react/PauseCircleOutlineIcon";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";

// Config
import {
  events,
  upperDress,
  lowerDress,
  shoes,
  accessTypes,
  languages,
} from "./config";

// Styles
import colors from "../../../styles/colors";
import { breakPoint } from "../../../styles/breakPoints";
import { useSelector } from "react-redux";
import { workHours } from "../../../utilities/get-work-hours-total";
import { isPastDateTime } from "../../../utilities/is_past_time";

const MiddleSection = (props) => {
  const { user, takenOverCorporation } = useSelector((state) => state.auth);

  const { booking, setBooking, setPriceAndState, validation } = props;
  const {start_time, end_time} = booking
  let workingHours = workHours({start_time, end_time});
  workingHours = workingHours ? parseFloat(workingHours) : 0;
  const setSelfPaidBreak = () => {
    setBooking({
      ...booking,
      self_paid_break: !booking.self_paid_break
    })
  }
  useEffect(() => {
    if((workingHours < 8 || !booking.food_included) && booking.self_paid_break){
      setBooking({
        ...booking,
        self_paid_break: false
      })
      props.addToast({
        message: "Minimum 8 timer fra-til samt personalemad",
        type: "warning",
        autoDismiss: true
      });
    }
  }, [workingHours, booking.food_included])
  const isPastDateAndTime = isPastDateTime(booking.date, booking.start_time);
  return (
    <div className={componentStyle(colors)}>
      <div className="first-section">
        <div className="first-container">
          <div className="extra-div">
            <div className="separator-div">
              <label>Type booking</label>
              <div className="arrangement-type-container">
                <select
                  value={booking.arrangement_type}
                  onChange={(e) => {
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                      arrangement_type_other: "",
                    });
                  }}
                  name="arrangement_type"
                >
                  {events.map((event, index) => (
                    <option
                      key={`arrangement-type-${index}`}
                      value={event.value}
                    >
                      {event.name}
                    </option>
                  ))}
                </select>
                <div className="icon-container">
                  <ChevronDownIcon />
                </div>
              </div>
              {!booking.arrangement_type && (
                <>
                  <label>Indtast arrangement</label>
                  <input
                    name="arrangement_type_other"
                    value={booking.arrangement_type_other}
                    onChange={(e) =>
                      setBooking({
                        ...booking,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </>
              )}
            </div>

            <div className="separator-div" style={{ marginBottom: "1.5rem" }}>
              <div>
                <label>Kan der forekomme overarbejde</label>
              </div>
              <div className="radio-container">
                <div className="radio-div">
                  <input
                    checked={parseFloat(booking.extra_work_hours) === 0}
                    type="radio"
                    className="radio"
                    name="extra_work_hours"
                    onChange={(e) =>
                      setBooking({ ...booking, [e.target.name]: 0 })
                    }
                  />
                  <span>Nej</span>
                </div>
                <div className="radio-div">
                  <input
                    checked={parseFloat(booking.extra_work_hours) === 0.5}
                    type="radio"
                    className="radio"
                    name="extra_work_hours"
                    onChange={(e) =>
                      setBooking({ ...booking, [e.target.name]: 0.5 })
                    }
                  />
                  <span>30 m.</span>
                </div>
                <div className="radio-div">
                  <input
                    checked={parseFloat(booking.extra_work_hours) === 1}
                    type="radio"
                    className="radio"
                    name="extra_work_hours"
                    onChange={(e) =>
                      setBooking({ ...booking, [e.target.name]: 1 })
                    }
                  />
                  <span>1 time</span>
                </div>
                <div className="radio-div">
                  <input
                    checked={parseFloat(booking.extra_work_hours) === 1.5}
                    type="radio"
                    className="radio"
                    name="extra_work_hours"
                    onChange={(e) =>
                      setBooking({ ...booking, [e.target.name]: 1.5 })
                    }
                  />
                  <span>1.5 time</span>
                </div>
              </div>
              <p className="invalid-text">
                {validation.extra_work_hours?.message}
              </p>
            </div>

            <div className="separator-div">
              <div>
                <label>Vil der være personale mad</label>
              </div>
              <div className="radio-container">
                <div className="radio-div">
                  <input
                    checked={
                      booking.food_included === 0 ||
                      booking.food_included === false
                    }
                    type="radio"
                    className="radio"
                    name="food_included"
                    value={booking.food_included}
                    onChange={(e) =>
                      setBooking({ ...booking, [e.target.name]: 0 })
                    }
                  />
                  <span>Nej</span>
                </div>

                <div className="radio-div">
                  <SilverwareForkKnifeIcon />
                </div>
                <div className="radio-div">
                  <input
                    checked={booking.food_included === 1}
                    type="radio"
                    className="radio"
                    name="food_included"
                    value={booking.food_included}
                    onChange={(e) =>
                      setBooking({ ...booking, [e.target.name]: 1 })
                    }
                  />
                  <span>Ja</span>
                </div>
              </div>
              <p className="invalid-text">{validation.food_included?.message}</p>
            </div>
            <div className="separator-div">
              <div className="self_paid_break" data-for={"switch-tooltip"} data-tip>
                <Switch
                  className="worker-switch"
                  disabled={workingHours < 8 || !booking.food_included}
                  onChange={() => {
                    setSelfPaidBreak();
                  }}
                  checked={booking.self_paid_break}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20}
                  width={40}
                />
                <PauseCircleOutline style={{marginLeft: '15px'}}/>
                <span style={{marginLeft: '15px', color: (workingHours < 8 || !booking.food_included) ? colors.grey_30 : ''}}>Selvbetalt spise-pause (-30 m.)</span>
                <ReactTooltip
                      id={`switch-tooltip`}
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      Minimum 8 timer fra-til samt personalemad
                    </ReactTooltip>
              </div>
            </div>
            
          </div>

          <div className="extra-div">
            <div className="separator-div">
              <label>Overdel</label>
              <div style={{ position: "relative" }}>
                <select
                  name="upper_dress_code"
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                      upper_dress_code_other: "",
                    })
                  }
                  value={booking.upper_dress_code}
                  style={{ paddingLeft: "2.5rem" }}
                >
                  {upperDress.map((upper, index) => (
                    <option
                      key={`upper-dress-key-${index}`}
                      value={upper.value}
                    >
                      {upper.name}
                    </option>
                  ))}
                </select>
                <div className="png-div-left">
                  <ChevronDownIcon />
                </div>
                <div className="png-div-right">
                  <TshirtCrewIcon />
                </div>
              </div>
              {!booking.upper_dress_code && (
                <>
                  <label>Indtast selvalgt overdel</label>
                  <input
                    value={booking.upper_dress_code_other}
                    name="upper_dress_code_other"
                    onChange={(e) =>
                      setBooking({
                        ...booking,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </>
              )}
            </div>

            <div className="separator-div">
              <label>Buks</label>
              <div style={{ position: "relative" }}>
                <select
                  name="lower_dress_code"
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                      lower_dress_code_other: "",
                    })
                  }
                  value={booking.lower_dress_code}
                  style={{ paddingLeft: "2.5rem" }}
                >
                  {lowerDress.map((lower, index) => (
                    <option key={`lower-dress-${index}`} value={lower.value}>
                      {lower.name}
                    </option>
                  ))}
                </select>
                <div className="png-div-left">
                  <ChevronDownIcon />
                </div>
                <div className="png-div-right">
                  <HumanMaleIcon />
                </div>
              </div>

              {!booking.lower_dress_code && (
                <>
                  <label>Indtast selvalgt bukser</label>
                  <input
                    value={booking.lower_dress_code_other}
                    name="lower_dress_code_other"
                    onChange={(e) =>
                      setBooking({
                        ...booking,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </>
              )}
            </div>

            <div className="separator-div">
              <label>Sko</label>
              <div style={{ position: "relative" }}>
                <select
                  name="shoes_dress_code"
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                      shoes_dress_code_other: "",
                    })
                  }
                  value={booking.shoes_dress_code}
                  style={{ paddingLeft: "2.5rem" }}
                >
                  {shoes.map((shoe, index) => (
                    <option key={`shoe-key-${index}`} value={shoe.value}>
                      {shoe.name}
                    </option>
                  ))}
                </select>
                <div className="png-div-left">
                  <ChevronDownIcon />
                </div>
                <div className="png-div-right">
                  <ShoeFormalIcon />
                </div>
              </div>

              {!booking.shoes_dress_code && (
                <>
                  <label>Indtast selvalgt sko</label>
                  <input
                    onChange={(e) =>
                      setBooking({
                        ...booking,
                        [e.target.name]: e.target.value,
                      })
                    }
                    value={booking.shoes_dress_code_other}
                    name="shoes_dress_code_other"
                  />
                </>
              )}
            </div>

            <div className="separator-div">
              <label>Medbring</label>
              <div style={{ position: "relative" }}>
                <div className="png-div-right">
                  <BagCheckedIcon />
                </div>
                <input
                  placeholder="Angiv medbring"
                  name="item_to_bring"
                  value={booking.item_to_bring}
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                      upper_dress_code_other: "",
                    })
                  }
                  style={{ paddingLeft: "2.5rem" }}
                />
              </div>
            </div>

            <div className="separator-div">
              <label>Krav til sprog</label>
              <div style={{ position: "relative" }}>
                <select
                  name="language"
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                    })
                  }
                  value={booking.language}
                  style={{ paddingLeft: "2.5rem" }}
                >
                  <option value="" disabled hidden>
                    Vælg sprog
                  </option>
                  {languages.map((language, index) => (
                    <option key={index} value={language.value}>
                      {language.name}
                    </option>
                  ))}
                </select>
                <div className="png-div-left">
                  <ChevronDownIcon />
                </div>
                <div className="png-div-right">
                  <AccountVoice />
                </div>
              </div>
              <p className="invalid-text">{validation.language?.message}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="second-section">
        <div className="extra-div">
          <div className="separator-div">
            <label>Uddybende job beskrivelse</label>
            <textarea
              name="job_description"
              value={booking.job_description}
              onChange={(e) =>
                setBooking({ ...booking, [e.target.name]: e.target.value })
              }
              rows="9"
              placeholder="Hvad skal i bruge hjælp til?"
            />
          </div>
        </div>

        <div className="extra-div">
          <div className="separator-div">
            <label>Kontaktperson og tlf. ved ankomst</label>
            <input
              name="contact_person"
              value={booking.contact_person}
              onChange={(e) => {
                setBooking({ ...booking, [e.target.name]: e.target.value });
              }}
              placeholder="Navn og mobil nr."
            />
            <p className="invalid-text">{validation.contact_person?.message}</p>
          </div>
          <div className="separator-div">
            <label>Finde frem</label>
            <div className="relative">
              <select
                name="access_information"
                onChange={(e) =>
                  setBooking({
                    ...booking,
                    [e.target.name]: e.target.value,
                    access_information_other: "",
                  })
                }
                value={booking.access_information}
              >
                {accessTypes.map((access, index) => (
                  <option key={`access-index-${index}`} value={access.value}>
                    {access.name}
                  </option>
                ))}
              </select>
              <div className="png-div-left">
                <ChevronDownIcon />
              </div>
            </div>

            {!booking.access_information && (
              <>
                <label>Angiv indgang</label>
                <input
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                    })
                  }
                  value={booking.access_information_other}
                  name="access_information_other"
                />
              </>
            )}
          </div>

          <div className="separator-div">
            <label>
              Ønsker du at tilbyde personale transporttillæg
              <span className="text-red-600">*</span>
            </label>
            <div className="radio-container">
              <div className="radio-div">
                <input
                  checked={parseFloat(booking.transport_wage) === 0}
                  type="radio"
                  className="radio"
                  name="transport_wage"
                  value={0}
                  onChange={(e) => setPriceAndState(e)}
                />
                <span>Nej</span>
              </div>

              <div className="radio-div">
                <input
                  checked={parseFloat(booking.transport_wage) === 0.5}
                  type="radio"
                  className="radio"
                  name="transport_wage"
                  value={0.5}
                  onChange={(e) => setPriceAndState(e)}
                />
                <span>30 m.</span>
              </div>

              <div className="radio-div">
                <input
                  checked={parseFloat(booking.transport_wage) === 1}
                  type="radio"
                  className="radio"
                  name="transport_wage"
                  value={1}
                  onChange={(e) => setPriceAndState(e)}
                />
                <span>1 time</span>
              </div>

              <div className="radio-div">
                <input
                  checked={parseFloat(booking.transport_wage) === 1.5}
                  type="radio"
                  className="radio"
                  name="transport_wage"
                  value={1.5}
                  onChange={(e) => setPriceAndState(e)}
                />
                <span>1.5 time</span>
              </div>

              <div className="radio-div">
                <input
                  checked={parseFloat(booking.transport_wage) === 2}
                  type="radio"
                  className="radio"
                  name="transport_wage"
                  value={2}
                  onChange={(e) => setPriceAndState(e)}
                />
                <span>2 timer</span>
              </div>
            </div>
            <p className="invalid-text">{validation.transport_wage?.message}</p>
          </div>

          <div
            className="separator-div"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="price-container">
              <label>Personale timeløn</label>
              <div style={{ position: "relative" }}>
                <input
                  name="hourly_wage"
                  value={booking.hourly_wage}
                  onChange={(e) => {
                    /^[0-9,]+$/.test(e.target.value) && setPriceAndState(e);
                  }}
                />
                <div className="png-div-left">
                  <p className="text-sm">DKK</p>
                </div>
              </div>
              <p className="invalid-text">{validation.hourly_wage?.message || validation.fee?.message}</p>
            </div>
          </div>

          {(user.is_admin || takenOverCorporation) && (
            <div className="not-emails">
              <label>Ikke send emails</label>
              <input
                name="is_email_script_allowed"
                defaultChecked={!(booking.is_email_script_allowed) || isPastDateAndTime}
                onClick={(e) => setBooking({
                  ...booking,
                  [e.target.name]: !booking.is_email_script_allowed
                })}
                type="checkbox"
                disabled={isPastDateAndTime}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const componentStyle = (colors) => css`
  .header-title {
    font-weight: 700;
    font-size: 1.125rem;
  }

  .radio {
    cursor: pointer;
  }

  .price-container {
    width: 8rem;
  }

  .invalid-text {
    text-align: center;
    color: ${colors.red};
    font-size: 0.6rem;
  }

  select {
    cursor: pointer;
    display: block;
    font-size: 0.75rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
    outline: none;
  }
  select:hover {
    border: 1px solid ${colors.black};
  }

  select::placeholder {
    background-color: ${colors.placeholder};
  }

  input,
  textarea {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }

  textarea {
    height: 100%;
  }

  input:hover,
  textarea:hover {
    border: 1px solid ${colors.black};
  }

  input::placeholder,
  textarea::placeholder {
    color: ${colors.placeholder};
  }

  hr {
    border-top-width: 1px;
    margin-bottom: 0.5rem;
  }

  .separator-div {
    margin-bottom: 0.6rem;
  }
  .self_paid_break{
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top:6px;
  }
  .png-div-left,
  .png-div-right {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: ${colors.black};
  }

  .png-div-right {
    left: 0;
    right: auto;
  }

  .first-section {
    position: relative;
    margin-bottom: 1.25rem;
  }

  .first-container {
    display: flex;
    justify-content: center;
    .language-label {
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: ${breakPoint.sm}px) {
      flex-direction: column;
    }
  }
  .extra-div {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;

    &:first-of-type {
      margin-right: 0.5rem;
      @media screen and (max-width: ${breakPoint.sm}px) {
        margin-right: 0;
      }
    }

    &:last-of-type {
      margin-left: 0.5rem;
      @media screen and (max-width: ${breakPoint.sm}px) {
        margin-left: 0;
      }
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      width: 100%;
      margin: 0;
    }

    label {
      letter-spacing: 0.025em;
      color: ${colors.black};
      font-size: 12px;
      margin-bottom: 0.25rem;
      margin-left: 0.25rem;
    }

    .radio-container {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .radio-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-justify-content: center;
        input {
          margin-bottom: 0.5rem;
          width: 20px;
          height: 20px;
          accent-color: darkgray;
        }

        span {
          margin-left: 0.5rem;
          font-size: 0.65rem;
          padding-right: 0.5rem;
        }
      }
    }

    div:first-child {
      .icon-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      .arrangement-type-container {
        position: relative;
      }
    }
  }

  div.not-emails {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    margin: 1rem 0;

    label {
      margin-right: 0.5rem;
    }

    input {
      width: auto;
      cursor: pointer;
    }
  }

  .second-section {
    display: flex;
    position: relative;
    @media screen and (max-width: ${breakPoint.sm}px) {
      flex-direction: column;
    }
  }
`;

export default MiddleSection;
