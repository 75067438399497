// Libs
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import moment from "moment";

// Style
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import CarBackIcon from "mdi-react/CarBackIcon";
import GoldIcon from "mdi-react/GoldIcon";
import CashMultipleIcon from "mdi-react/CashMultipleIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import CancelIcon from "mdi-react/CancelIcon";
import SendIcon from "mdi-react/SendIcon";
import colors from "../../../styles/colors";
import ClipboardTextOutlineIcon from "mdi-react/ClipboardTextOutlineIcon";
import WalkIcon from "mdi-react/WalkIcon";
import PauseCircleOutline from "mdi-react/PauseCircleOutlineIcon"
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import HumanMaleIcon from "mdi-react/HumanMaleIcon";
import BagCheckedIcon from "mdi-react/BagCheckedIcon";
import SilverwareForkKnifeIcon from "mdi-react/SilverwareForkKnifeIcon";
import AccountVoice from "mdi-react/RecordVoiceOverIcon";
import AlertCircle from "mdi-react/AlertCircleIcon";
import ArrowLeftRightIcon from "mdi-react/ArrowLeftRightBoldIcon";
import Alert from "mdi-react/AlertIcon";
import Call from "mdi-react/CallIcon";
import AddressUrl from "mdi-react/ExternalLinkIcon";

// Components
import ReactStars from "react-stars";
import NavigationBar from "../../NavigationBar";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";
import Spinner from "../../ui/Spinner";
import DateViewer from "../../ui/DateViewer";
import BeforeShiftModal from "./BeforeShiftModal";
import SkillsModal from "../editEmployee/SkillsModal";
import SimpleCancelShiftsModal from "./SimpleCancelShiftsModal";
import CancelShiftModal from "./CancelShiftModal";
import ErrorBox from "../../ui/ErrorBox";

// Utilities
import req from "../../../utilities/request-utility";
import { transportWageTimeConverter } from "../../../utilities/transport-wage-time-converter";
import { staffTypeLanguage } from "../../../utilities/language-translator";
import { doesWorkSkillExist } from "../../../utilities/check-if-workSkill-exists";
import { getWorkSkillQuestion } from "../../../utilities/get-workskill-question";
import { addCommas } from "../../../utilities/add_commas";
import { remove_commas } from "../../../utilities/remove_commas";
import { workHours } from "../../../utilities/get-work-hours-total";
import staff_type_converter from "../../../utilities/staff_type_converter";
import { addStrings } from "../../../utilities/add_string"
import { isBookingOpen } from "../../../utilities/check-booking-open";

// Actions
import { addToast, hideModal, showModal } from "../../../actions/uiActions";
import { updateWorkSkill } from "../../../actions/authActions";

// Config
import shiftTypes from "../../../config/shiftTypes";

// Styles
import { breakPoint } from "../../../styles/breakPoints";

const BookingInformation = (props) => {
  const { bookingId } = props.match.params;
  const history = useHistory();

  // State
  const [booking, setBooking] = useState(null);
  const [stempleButton, setStempleButton] = useState(false);
  const [employeeReadAdminMessage, setEmployeeReadAdminMessage] =
    useState(false);
  const [checkBookingOpen, setCheckBookingOpen] = useState(null)
  const [remainingTime, SetRemainingTime] = useState(null)
  const [remainingHours, SetRemainingHours] = useState(null)

  // User information
  const { workSkills } = useSelector((state) => state.auth.user);

  // Page state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [globalMessage, setGlobalMessage] = useState("");

  const calculateRemainingTime = (date) => {
    let currentDate = moment().toISOString();

    const bookingDate = moment(date); // create a new moment object from the date variable
    const twoDaysBefore = bookingDate.subtract(2, "days").startOf("day").add(6, "hours");

    SetRemainingHours(twoDaysBefore.diff(currentDate, "hours"));

    let result = currentDate >= twoDaysBefore.toISOString() ? true : false
    SetRemainingTime(result)
  };

  useEffect(() => {
    getData();

    // get global admin message
    req()
      .get(`admin/bookings/global-message`)
      .then((res) => {
        const message = res.data.global_message || "";
        setGlobalMessage(message)
      });

    // eslint-disable-next-line
  }, []);

  const transportWageConverter = {
    1.5: "1,5 time",
    0.5: "30 m.",
    1: "1 time",
    2: "2 timer",
    0: "0",
  };

  const getData = async () => {
    await req()
      .get(`employees/bookings/${bookingId}`)
      .then(({ data: booking }) => {
        setBooking(booking);
        setCheckBookingOpen(isBookingOpen(booking?.date, booking?.start_time))
        calculateRemainingTime(booking?.date)
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
        props.addToast({
          message: "Kunne ikke hente information",
          type: "error",
          autoDismiss: true,
        });
      });
  };

  const deleteShift = () => {
    setLoading(true);
    req()
      .put(`shifts/employee/cancel/${bookingId}`)
      .then(() => {
        history.push("/");
      })
      .catch(() => {
        props.addToast({
          message: "Noget gik galt",
          type: "error",
          autoDismiss: true,
        });
      });
  };

  const applyForBooking = () => {
    if (doesWorkSkillExist(booking.jobRole.id, workSkills)) {
      props.showModal({
        title: "ANSØG JOB",
        content: (
          <BeforeShiftModal
            setLoading={setLoading}
            getData={() => getData()}
            booking={booking}
          />
        ),
      });
    } else {
      props.showModal({
        hideTop: true,
        disableBackgroundCancel: true,
        content: (
          <SkillsModal
            update={(workSkills) => {
              props.updateWorkSkill(workSkills, () =>
                props.showModal({
                  title: "ANSØG JOB",
                  content: (
                    <BeforeShiftModal
                      setLoading={setLoading}
                      getData={() => getData()}
                      booking={booking}
                    />
                  ),
                })
              );
            }}
            skillId={booking.jobRole.id}
            skillTitle={booking.jobRole.job_title}
          />
        ),
      });
    }
  };

  const employeeCheckin = () => {
    if (stempleButton) {
      setEmployeeReadAdminMessage(!employeeReadAdminMessage);

      req()
        .put(`shifts/employee/checkin/${bookingId}`)
        .then(() => {
          props.addToast({
            message: "Medarbejder check-in",
            type: "success",
            autoDismiss: true,
          });
        })
        .catch(() => {
          props.addToast({
            message: "Noget gik galt",
            type: "error",
            autoDismiss: true,
          });
        });
    }
  };


  const bookingWorkHours = workHours({
    start_time: booking?.start_time,
    end_time: booking?.end_time,
  });

  const hourlyWage = booking?.hourly_wage;
  const transportWage = booking?.transport_wage;
  const self_paid_break = booking?.self_paid_break;

  let totalHourAndTranportWage
  let totalAmount

  if (transportWage) {
    let hourAndTranportWage = addStrings(bookingWorkHours, booking?.transport_wage)
    if(self_paid_break){
      hourAndTranportWage = addCommas(addStrings(hourAndTranportWage, "0.5", "subtract"))
    }
  
    totalHourAndTranportWage = addCommas(hourAndTranportWage)
    totalAmount = addCommas(parseFloat(hourAndTranportWage.replace(",", ".")) * parseFloat(hourlyWage.replace(",", ".")))
  }

  // const totalAmount = addCommas(
  //   (
  //     1.125 *
  //       remove_commas(booking?.hourly_wage) *
  //       (parseFloat(bookingWorkHours) + parseFloat(booking?.transport_wage)) +
  //     remove_commas(booking?.fee) *
  //       (parseFloat(bookingWorkHours) + parseFloat(booking?.transport_wage))
  //   ).toFixed(2)
  // );

  return (
    <PageContainer NavigationBar={true}>
      <NavigationBar />
      <NavigateBackArrow navigateURL={() => history.push("/")} />

      {loading && !error && <Spinner title={"Indlæser..."} />}
      {!loading && error && <ErrorBox />}
      {!loading && !error && (
        <div className={componentStyle()}>
          <div className="top-section-container">
            <div className="corporation-box">
              {booking.corporation.icon_image ? (
                <div className="image-container">
                  <img
                    src={`${booking.corporation.baseURL_image}h_100,w_100/dpr_2.0/r_max/${booking.corporation.icon_image}`}
                    alt="icon"
                  />
                </div>
              ) : (
                <div className="image-container">
                  <img style={{ border: "1px solid #CFCBCB", backgroundColor: "#f2f2f2" }} />
                </div>
              )}
              <div className="rating-name-container">
                <p className="corporation-name">{booking.corporation.name}</p>
                <div className="rating-container">
                  <p>
                    {booking.corporation.rating.nrOfRatings > 1
                      ? `(${booking.corporation.rating.nrOfRatings} ratings)`
                      : `(${booking.corporation.rating.nrOfRatings} rating)`}
                  </p>
                  <ReactStars
                    count={5}
                    edit={false}
                    value={booking.corporation.rating.rating}
                    size={16}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="middle-section">
            <div className="middle-top-section">
              <div className="date-container">
                <DateViewer
                  className="date-style"
                  style={{ marginRight: "0.5rem" }}
                  date={booking.date}
                  size={20}
                  showYear={true}
                />
              </div>

              <div className="content-box">
                <div className="container-a">
                  <p className="container-a-title">
                    <ClipboardTextOutlineIcon
                      style={{
                        color: "#4A4A4A",
                        marginRight: "6px",
                        height: "15px",
                        width: "15px"
                      }}
                    />{" "}
                    <span>{booking.arrangement_type || booking.arrangement_type_other}</span>
                  </p>

                  <div className="container-a-b">
                    <div className="time-container">
                      <ClockOutlineIcon
                        style={{ color: "#4A4A4A", marginRight: "5px" }}
                        size={15}
                      />
                      <p> {`${booking.start_time} - ${booking.end_time}`}</p>
                      <p style={{ fontSize: "12.5px" }}> {`[${addCommas(bookingWorkHours)} /t]`}</p>
                    </div>
                    <p className="staff-type-container">
                      <WalkIcon
                        size={15}
                        style={{ color: "#4A4A4A", marginRight: "5px" }}
                      />{" "}
                      {booking.jobRole?.job_title}
                    </p>
                    <div className="address-container">
                      <MapMarkerIcon
                        size={15}
                        style={{ color: "#4A4A4A", marginRight: "5px" }}
                      />

                      {booking.address_url ? (
                        <>
                          <a href={booking.address_url} target="_blank">
                            <p>
                              {`${booking.address}, ${booking.zip_code} ${booking.city}`}
                            </p>
                            <AddressUrl
                              style={{ width: "12px", marginLeft: "3px" }}
                            />
                          </a>
                        </>
                      ) : (
                        <p>
                          {`${booking.address}, ${booking.zip_code} ${booking.city}`}
                        </p>
                      )}
                    </div>
                    {
                      transportWage != 0 ? (
                        <div className="address-container">
                          <CarBackIcon
                            size={15}
                            style={{ color: "#4A4A4A", marginRight: "5px" }}
                          />
                          <p>
                            Transporttillæg: {transportWageConverter[transportWage]}
                          </p>
                        </div>
                      ) : <></>
                    }
                    {
                      booking?.self_paid_break ? (
                        <div className="address-container">
                          <PauseCircleOutline
                            size={15}
                            style={{ color: "#4A4A4A", marginRight: "5px" }}
                          />
                          <p>
                            Selvbetalt pause: -30 m.
                          </p>
                        </div>
                      ) : <></>
                    }
                    <div className="address-container" style={{ marginTop: '4px', alignItems: 'flex-start' }}>
                      <GoldIcon
                        size={15}
                        style={{ color: "#4A4A4A", marginRight: "5px" }}
                      />
                      <span style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>
                          {`${totalAmount} dkk [${totalHourAndTranportWage} /t]  ${hourlyWage} kr./t \u00A0  \u00A0`}
                        </p>
                        <p className="paydate">
                          {`(Udbetal d. ${booking.paymentDate})`}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="container-b">
                  <div>
                    {booking?.transport_wage != 0 && (
                      <p>{`Transporttillæg:  ${
                        transportWageConverter[booking.transport_wage]
                      }`}</p>
                      )}
                      <p>{`[${totalHourAndTranportWage} t]  ${hourlyWage} dkk/t \u00A0  \u00A0 ${totalAmount} dkk `}</p>
                      <p>{`(Udbetales d. ${booking.paymentDate})`}</p>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="middle-bottom-section">
              <p className="info-section">Info</p>

              <div className="content-section">
                <div className="section-box">
                  <div className="box-title">
                    <HumanMaleIcon size={15} className="title-icon" />
                    <p>Uniform:</p>
                  </div>
                  <div className="box-content">
                    <p>
                      {booking.upper_dress_code
                        ? booking.upper_dress_code
                        : booking.upper_dress_code_other}
                    </p>
                    <p>
                      {booking.lower_dress_code
                        ? booking.lower_dress_code
                        : booking.lower_dress_code_other}
                    </p>
                    <p>
                      {booking.shoes_dress_code
                        ? booking.shoes_dress_code
                        : booking.shoes_dress_code_other}
                    </p>
                  </div>
                </div>
                <div className="section-box">
                  <div className="box-title">
                    <BagCheckedIcon size={15} className="title-icon" />
                    <p>Medbring:</p>
                  </div>
                  <div className="box-content">
                    <p>{booking.item_to_bring ? booking.item_to_bring : "-"}</p>
                  </div>
                </div>
                <div className="section-box">
                  <div className="box-title">
                    <ClockOutlineIcon size={15} className="title-icon" />
                    <p>Overarbejde:</p>
                  </div>
                  <div className="box-content">
                    <p>
                      {booking.extra_work_hours > 0 ? (
                        `Der kan forekomme ${transportWageTimeConverter(
                          booking.extra_work_hours,
                          "dk"
                        )} ekstra arbejde`
                      ) : (
                        <CancelIcon size={15} className="cancel-icon" />
                      )}
                    </p>
                  </div>
                </div>
                <div className="section-box">
                  <div className="box-title">
                    <SilverwareForkKnifeIcon size={15} className="title-icon" />
                    <p>Personalemad:</p>
                  </div>
                  <div className="box-content">
                    <p>{booking.food_included ? "Ja" : "Nej"}</p>
                  </div>
                </div>
                <div className="section-box">
                  <div className="box-title">
                    <AccountVoice size={15} className="title-icon" />
                    <p>Language Requirements:</p>
                  </div>
                  <div className="box-content">
                    <p>
                      {booking.language
                        ? booking.language === "Engelsk"
                          ? ` English 🇬🇧`
                          : booking.language === "Dansk"
                            ? `Danish 🇩🇰`
                            : `Danish 🇩🇰 or English 🇬🇧`
                        : ``}
                    </p>
                  </div>
                </div>
                <div className="section-box">
                  <div className="box-title">
                    <ClipboardTextOutlineIcon size={15} className="title-icon" />
                    <p>Uddybende jobbeskrivelse:</p>
                  </div>
                  <div className="box-content">
                    <p>
                      {booking.job_description ? booking.job_description : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {booking.shift && booking.shift.status === shiftTypes.selected ? (
            <div className="approved-container">

              <div className="approved-container-content">
                <div className="column-box">
                  <div className="column-box-title">
                    <AlertCircle size={15} className="title-icons" />
                    {!remainingTime ? (
                      <p>
                        Information tilgængelig 48 timer før jobstart!
                      </p>
                    ) : (
                      <p>Du skal stemple ind og læse info herunder!</p>
                    )}
                  </div>
                </div>
                <div className="column-box">
                  <div className="column-box-title">
                    <ArrowLeftRightIcon size={15} className="title-icons" />
                    <p> Hvordan find jeg frem:</p>
                  </div>
                  {booking.shift.status === shiftTypes.selected &&
                    remainingTime && (
                      <p className="column-box-title-content">
                        {booking.access_information
                          ? booking.access_information
                          : booking.access_information_other}
                      </p>
                    )}
                </div>

                <div className="column-box">
                  <div className="column-box-title">
                    <Call size={15} className="title-icons" />
                    <p>Kontaktperson:</p>
                  </div>
                  {booking.shift.status === shiftTypes.selected &&
                    remainingTime && (
                      <p className="column-box-title-content">
                        {booking.contact_person}
                      </p>
                    )}
                </div>

                <div className="column-box">
                  <div className="column-box-title">
                    {booking.shift.status === shiftTypes.selected &&
                      !remainingTime && (
                        <>
                          {" "}
                          <Alert size={15} className="title-icons" />{" "}
                          <p>Meld klar til job!:</p>
                        </>
                      )}
                  </div>
                </div>
              </div>

              {booking.shift &&
                booking.shift.status === shiftTypes.selected &&
                !remainingTime && (
                  <div className="stamp-line">
                    <p>
                      (Det er muligt 48 timer før, at melde klar til job)
                    </p>
                  </div>
                )}

              {booking.shift &&
                booking.shift.status === shiftTypes.selected &&
                remainingTime && (
                  <>
                    <div
                      className={`${employeeReadAdminMessage || booking.shift.employee_checkin
                        ? "admin-message-active1"
                        : "admin-message1"
                        }`}
                    >
                      <div style={{ display: "flex" }}>
                        <Alert style={{ padding: "0 2px", height: "15px", width: "15px" }} />
                        <p style={{ marginLeft: "3px" }}>
                          Besked fra Admin - TjenerTeamet.
                        </p>
                      </div>
                      <div className="admin-message-content1">
                        {booking?.admin_message && (<p>{booking.admin_message}</p>)}

                        <p>{booking.corporation.admin_message}</p>
                        <p>{globalMessage}</p>
                      </div>
                    </div>
                    <div className="admin-message-checkbox1">
                      <p>Jeg har læst og forstaet info til jobbet</p>
                      <input
                        disabled={booking.shift.employee_checkin || !checkBookingOpen}
                        type="checkbox"
                        name=""
                        checked={
                          stempleButton || booking.shift.employee_checkin
                        }
                        onClick={() => {
                          setStempleButton(!stempleButton);
                        }}
                      />
                    </div>

                    <button
                      disabled={employeeReadAdminMessage || booking.shift.employee_checkin}
                      onClick={() => {
                        employeeCheckin();
                      }}
                      className={`right ${employeeReadAdminMessage || booking.shift.employee_checkin === true
                        ? "employee-checkin-successfully"
                        : stempleButton === true ||
                          booking.shift.employee_checkin
                          ? "stemple-ind-btn-active"
                          : "stemple-ind-btn"
                        }`}
                    >
                      {employeeReadAdminMessage || booking.shift.employee_checkin
                        ? "DU ER KLAR TIL JOB"
                        : stempleButton
                          ? "Meld klar til job-Klik her"
                          : "Meld klar til job!"}
                    </button>

                    <div className="report-message">
                      <p>( Det er muligt 48 timer før at melde klar til job )</p>
                    </div>
                  </>
                )}
            </div>
          ) : (
            <div className="approved-container">

              <div className="approved-container-content">
                <div className="column-box">
                  <div className="column-box-title">
                    <AlertCircle size={15} className="title-icons" />
                    <p>
                      Dette felt bliver synligt, hvis du bliver godkendt til jobbet:
                    </p>
                  </div>
                </div>
                <div className="column-box">
                  <div className="column-box-title">
                    <ArrowLeftRightIcon size={15} className="title-icons" />
                    <p> Hvordan find jeg frem:</p>
                  </div>
                </div>

                <div className="column-box">
                  <div className="column-box-title">
                    <Call size={15} className="title-icons" />
                    <p>Kontaktperson:</p>
                  </div>
                </div>

                <div className="column-box">
                  <div className="column-box-title">
                    <Alert size={15} className="title-icons" />
                    <p>Meld klar til job!:</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="btn-section-container">
            <div className={`${booking.shift && booking.shift.status === shiftTypes.applied ? "condition-based-btn" : ""}`} >
              {(booking.shift &&
                booking.shift.status === shiftTypes.selected) ||
                (booking.shift && booking.shift.status === shiftTypes.applied) ? (
                <Fragment>
                  {booking.shift &&
                    booking.shift.status === shiftTypes.selected && (
                      <button
                        onClick={() =>
                          props.showModal({
                            content: <CancelShiftModal size={15} bookingId={bookingId} />,
                          })
                        }
                        className="cancel-policy-btn"
                      >
                        Afbudspolitik
                      </button>
                    )}
                  <button
                    onClick={
                      booking.shift.status === shiftTypes.selected
                        ? () =>
                          props.showModal({
                            content: (
                              <CancelShiftModal size={15} bookingId={bookingId} />
                            ),
                          })
                        : () =>
                          props.showModal({
                            content: (
                              <SimpleCancelShiftsModal
                                size={15}
                                cancelShift={() => deleteShift()}
                              />
                            ),
                          })
                    }
                    className="cancel-btn"
                  >
                    Annullere ansøgning
                  </button>
                  {booking.shift &&
                    booking.shift.status === shiftTypes.applied && <button className="apply-btn">ANSØGT (venter svar)</button>}
                </Fragment>
              ) : (
                <button
                  onClick={() => applyForBooking()}
                  className="submit-btn right"
                >
                  <span>Send ansøgning</span>
                  <SendIcon size={15} />
                </button>
              )}

            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  updateWorkSkill: bindActionCreators(updateWorkSkill, dispatch),
});

const componentStyle = (props) => css`

  div.approved-container{
    background-color: #ffffff;
    padding: 0.5rem 2rem;
    color: #4A4A4A;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 576px) {
      padding-right: 1.5rem;
    }


    .info-line{
      display: flex;
      align-items: center;
      padding-left: 5.28rem;
      margin-bottom: 0.5rem;

      @media screen and (max-width: 576px) {
        padding-left: 1.7rem;
      }

      .info-icon{
        min-width:15px;
        margin-left: 0.156rem;
        color: #8A7853;
      }

      p{
        font-weight: bold;
        padding-left: 0.188rem;
        font-size:0.9rem;
      }
    }

    .approved-container-content {
      display: flex;
      flex-direction: column;
      padding-left: 1.4rem;
      border: 1px solid #4A4A4A;
      border-radius: 0.313rem;
      margin-left: 4rem;
      p{
        font-size:0.9rem;
      }
      @media screen and (max-width: 576px) {
        margin-left: 1.2rem;
        padding: 0.6rem;
      }


      .column-box-title{
        display: flex;
        align-items: center;
        padding-top: 0.313rem;
        font-weight: bold;
      }

      .column-box-title-content{
        padding-left: 1.75rem;
        padding-bottom: 0.625rem;
      }
      .title-icons{
        margin-right: 0.188rem;
        padding: 0 1px;
        color: #8A7853;

        @media screen and (max-width: 576px) {
          // width: 1.3rem;
          // height: 1.3rem;
        }
  
      }
    }

    .stamp-line{
      text-align: center;
      margin: 0.945rem 0 0.625rem;
      font-weight: bold;
      background-color: #c7c7c7;
      margin-left: 64px;
      border-radius: 4px;
      padding: 10PX;
      COLOR: #4A4A4A;
      margin-bottom:0;
      font-size: 14px;

      @media screen and (max-width: 576px) {
        margin-left: 0px;
        font-size: 12px;
      }
    }

    .admin-message1 {
      display: flex;
      flex-direction: column;
      background-color: #565252;
      color: white;
      border-radius: 0.313rem;
      margin-top: 1.25rem;
      margin-left: 4rem;
      padding-left: 1.8rem;

      @media screen and (max-width: 576px) {
        margin-left: 0rem;
      }

      .admin-message-content1 {
        padding: 0.625rem 0;
        margin-left: 0.3rem;
        font-size: 0.938rem;
        margin-right: 1.25rem;
        white-space: pre-wrap;
        word-wrap: break-word;

        p{
          padding-top: 10px;
        }
      }

    }

    .admin-message-active1 {
      display: flex;
      flex-direction: column;
      background-color: #298129e0;
      color: white;
      border-radius: 0.313rem;
      margin-top: 1.25rem;
      margin-left: 4rem;
      padding-left: 1.8rem;

      @media screen and (max-width: 576px) {
        margin-left: 0;
      }

      .admin-message-content1 {
        padding: 0.625rem 0;
        margin-left: 0.3rem;
        font-size: 0.938rem;
        margin-right: 1.25rem;
        white-space: pre-wrap;
        word-wrap: break-word;

        p{
          padding-top: 10px;
        }
      }

    }
  }

  .admin-message-checkbox1 {
    display: flex;
    border-radius: 0.313rem;
    margin-left: 4rem;
    padding-left: 1.8rem;
    margin-top: 1rem;
    margin-bottom: 0.4rem;

    @media screen and (max-width: 576px) {
      margin-left: 1rem;
      padding-left: 0;
    }

    p {
      margin-right: 0.5rem;
      margin-left: 0.3rem;
      font-weight: 600;
      font-size:14px;
    }

    input {
      cursor: pointer;
      width: 20px;
      height: 20px;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #999;
        background-color: #d8d7d7;
        border-radius: 3px;
        margin-right: 8px;
        vertical-align: middle;
      }

      &:checked:before {
        background-image: linear-gradient(360deg,#2d3436 0%,#d3d3d3 100%);
        border: none;
      }

      &:checked:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 7px;
        width: 4px;
        height: 11px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .stemple-ind-btn{
    color: #424242;
    border: 1px solid ${colors.lightGrey};
    background-color: #5652523b;
    box-sizing: border-box;
    padding: 10px 5px;
    border-radius: 0.313rem;
    outline: none;
    margin-left: 4rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: 576px) {
      margin-left: 0;
    }
  }

  .stemple-ind-btn-active{
    color: #ffffff;
    border: 1px solid #565252;
    background-color: #565252;
    box-sizing: border-box;
    padding: 10px 5px;
    border-radius: 0.313rem;
    outline: none;
    margin-left: 4rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: 576px) {
      margin-left: 0;
    }
  }

  .employee-checkin-successfully{
    color: #ffffff;
    border: 1px solid ${colors.lightGrey};
    background-color: #008000e6;
    box-sizing: border-box;
    padding: 10px 5px;
    border-radius: 0.313rem;
    outline: none;
    margin-left: 4rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: 576px) {
      margin-left: 0;
    }
  }

  .report-message{
    display: flex;
    margin-top: 5px;
    margin-left: 4rem;
    justify-content: center;

    @media screen and (max-width: 576px) {
      margin-left: 0;
    }

    p{
      font-weight: 600;
      font-size:13px;
      text-align: center;
    }
  }

  div.btn-contain {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }

  .top-section-container {
    display: flex;
    justify-content: start;
    padding: 0.5rem 2rem;
    // margin-bottom: 0.625rem;
    @media screen and (max-width: ${breakPoint.sm}px) {
      align-items: flex-end;
      padding: 0.5rem 0.5rem;
    }

    .arrangement-box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .arrangement-title {
        font-size: 1.5rem;
        font-weight: 500;

        @media screen and (max-width: ${breakPoint.sm}px) {
          font-size: 1.12rem;
        }
      }

      .address-box {
        display: flex;
        align-items: center;

        p {
          font-size: 0.875rem;
          font-weight: 500;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.65rem;
          }
        }

        svg {
          height: 2rem;
          width: auto;
          margin-right: 0.5rem;

          @media screen and (max-width: ${breakPoint.sm}px) {
            height: 1rem;
          }
        }
      }
    }

    .corporation-box {
      display: flex;
      align-items: center;

      .image-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 0.625rem;
        @media screen and (max-width: ${breakPoint.sm}px) {
          margin-bottom: 0.5rem;
          align-items: flex-end;
        }

        img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;

          @media screen and (max-width: ${breakPoint.sm}px) {
            width: 4rem;
            height: 4rem;
          }
        }
      }
      .rating-name-container {
        .corporation-name {
          font-size: 1.25rem;
          font-weight: 500;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.85rem;
          }
        }

        .rating-container {
          display: flex;
          align-items: center;
          // justify-content: flex-end;

          p {
            // margin-left: 0.25rem;
            font-size: 0.75rem;
            font-weight: 1000;
            margin-right: 0.25rem;
          }
        }
      }
    }
  }

  .middle-section {
    background-color: ${colors.white};
    padding: 0.5rem 2rem;
    color: #4A4A4A;

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 0.5rem 0.5rem;
    }

    .middle-top-section {
      display: flex;
      margin-bottom: 0.75rem;
    }

    .date-container {
      display: flex;
      margin: 0 0.5rem;
      @media screen and (max-width: 576px) {
        margin: 0;
      }
      .date-style {
        .day,
        .month {
          font-size:19.2px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 1.063rem;
          }
          @media screen and (max-width: 576px) {
            font-size: 0.875rem;
          }
        }

        .date {
          font-size:32.5px;
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 1.25rem;
          }
          @media screen and (max-width: 576px) {
            font-size: 0.875rem;
          }
        }
        .year {
          font-size:19.2px;
          font-weight:600;
          @media screen and (max-width: 576px) {
            font-size: 0.938rem;
          }
        }
      }

      .date-box {
        display: flex;
        flex-direction: column;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-left: 0.5rem;

        .transport-fee-container {
          display: flex;
          align-items: center;
          margin-top: 0.25rem;

          @media screen and (max-width: ${breakPoint.sm}px) {
            svg {
              height: 1rem;
              width: 1rem;
            }
          }

          .transport-fee-title {
            font-size: 0.875rem;
            font-weight: 700;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.55rem;
            }
          }
        }

        .staff-type-label {
          text-align: center;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          background-color: ${colors.lightBlue};
          font-size: 0.75rem;
          border-radius: 0.25rem;
          color: ${colors.white};
          font-weight: 500;
          letter-spacing: 0.05em;
          width: auto;
          display: inline-block;
          text-transform: uppercase;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 0.65rem;
          }
        }

        .time-title {
          font-size: 1.25rem;
          letter-spacing: 0.05em;

          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: 1rem;
          }
        }

        .payment-box {
          display: flex;
          align-items: center;

          .money-icon {
            height: 1.5rem;
            width: auto;
            margin-right: 0.5rem;

            @media screen and (max-width: ${breakPoint.sm}px) {
              height: 1rem;
              width: 1rem;
            }
          }

          .wage-total {
            font-size: 0.875rem;
            font-weight: 700;
            margin-right: 0.25rem;
            letter-spacing: 0.05em;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.65rem;
            }
          }

          .wage-title {
            font-size: 0.875rem;
            margin-right: 0.5rem;
            letter-spacing: 0.05em;

            @media screen and (max-width: ${breakPoint.sm}px) {
              font-size: 0.75rem;
            }
          }
        }

        .payment-date {
          margin-top: 0.25rem;

          @media screen and (max-width: ${breakPoint.sm}px) {
            p {
              font-size: 0.75rem;
            }
          }
        }
      }
    }

    .content-box {
      display: flex;
      width: 100%;
      border-radius: 0.313rem;
      border: 1px solid #cbd5e0;
      justify-content: space-between;
      align-items:center;

      @media screen and (max-width: 576px) {
        flex-wrap: wrap;
        margin-right: 1rem;
      }
      .container-a {
        padding: 1rem;
        @media screen and (max-width: ${breakPoint.sm}px) {
          padding: 0.625rem;
        }
        .container-a-title {
          display: -webkit-box;
          -webkit-box-align: center;
          align-items: center;
          font-size: 0.9rem;
          margin-bottom: 0.25rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-weight: 600;
          color: #4A4A4A;
          @media screen and (max-width: 576px) {
            font-size: 0.875rem;
            white-space: initial;
            overflow: visible;
          }
          svg {
            min-width:15px;
          }
        }

        .container-a-b {
          .time-container {
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;
            svg {
              // margin-left: 0.125rem;
              min-width: 15px;
              min-height:15px;
              @media screen and (max-width: 576px) {
                width: 20px;
                margin-right: 0.438rem !important;
              }
            }
            p {
              font-size: 0.9rem;
              font-weight:500;
              margin-right: 0.5rem;
              @media screen and (max-width: 576px) {
                font-size: 0.875rem;
              }
            }
          }

          .wage-container {
            margin-bottom: 0.25rem;

            p {
              font-size: 0.75rem;
              color: #4A4A4A;
            }
          }

          .address-container,
          .staff-type-container {
            display: flex;
            font-size:14.4px;
            font-weight:500;
            align-items: center;
            .paydate{
              font-style: italic;
            }
            @media screen and (max-width: 576px) {
              font-size: 14px;
            }

            p {
              font-size: 0.75rem;
              color: #4A4A4A;
              display: inline-block;
              clear: both;
              // white-space: nowrap;
              overflow: hidden;
            }

            svg {
              margin-right: 2px;
              min-width: 15px;
            }

            a {
              display:flex;
              align-items: center;
            }
          }
        }
      }

      .container-b {
        display: flex;
        padding: 1rem;
        align-items: flex-end;
        @media screen and (max-width: 576px) {
          width: calc(45% - 0.313rem);
          padding: 0.625rem;
        }
        p {
          font-size: 0.75rem;
          text-align: right;
          font-weight:500;
          margin-bottom: 0.3rem;
          &:last-child {
            font-style: italic;
          }
            @media screen and (max-width: 576px) {
            font-size: 0.594rem;
            &:last-child {
              padding-bottom: 0.188rem;
            }
          }
        }
      }
    }

    .job-description {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: 2rem;
      overflow-wrap: break-word;
      white-space: pre-line;

      @media screen and (max-width: ${breakPoint.sm}px) {
        margin-bottom: 1rem;
        p {
          font-size: 0.8rem;
        }
      }
    }

    .middle-bottom-section {
      display: flex;

      .info-section {
        color: #4A4A4A;
        font-weight: 600;
        font-size: 1.25rem;
        margin: 0 0.9rem;
        @media screen and (max-width: 576px) {
          margin: 0 0.5rem 0 0;
          font-size: 1rem;
        }
      }

      .content-section {
        padding-left: 1.163rem;

        @media screen and (max-width: 760px) {
          padding-left: 0.813rem; 
        }

        .section-box {
          display: flex;
          flex-direction: column;

          .box-title {
            display: flex;
            align-items: center;
            .title-icon {
                min-width:15px;
              margin-right: 0.188rem;
              @media screen and (max-width: 576px) {
                // width: 1.3rem;
                // height: 1.3rem;
              }
        
            }

            p {
              font-weight: 600;
              font-size:0.9rem;
            }

            @media screen and (max-width: 576px) {
              font-size: 0.8rem;
            }
          }

          .box-content {
            margin-bottom: 0.5rem;
            margin-left: 1.688rem;
            white-space: break-spaces;
            p {
              font-size: 0.813rem;
              color: #4A4A4A;
              font-weight:500;
              @media screen and (max-width: 576px) {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }

    .booking-checkpoints-container {
      display: flex;
      flex-direction: column;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .right {
    float: right;
  }
  .btn-section-container {
    background-color: ${colors.white};
    padding: 1.25rem 2rem 0.5rem 6.5rem;

    .condition-based-btn{
      display: flex;
      justify-content: space-between;
    }

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 1.25rem 0.5rem;
      margin-left: 3rem;
      padding-left: 0px;
    }

    @media screen and (max-width: 575px) {
      margin-left: 2.8rem;
      margin-right: 0.8rem;
      button {
        font-size: 0.8rem !important;
        padding: 0.5rem !important;
      }
      svg{
        height:16px;
        width:16px;
      }
    }

    .countdown-container {
      max-width: 20rem;
      margin-right: 1rem;

      p {
        background-color: ${colors.darkerRed};
        color: ${colors.white};
        border-radius: 0.25rem;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 700;
        padding: 0.38rem 1rem;
        white-space: nowrap;
      }
    }

    .apply-btn{
      border: 1px solid #f8bd5d;
      color: #f8bd5d;
      font-weight: bold;
      font-size: 14px;
      border-radius: 5px;
      padding: 5px 15px;
      &:focus {
        outline: none;
      }
      @media screen and (max-width: 576px) {
        padding: 3px 8px;
      }
    }

    .cancel-policy-btn,
    .submit-btn,
    .cancel-btn {
      font-size: 0.875rem;
      border: 1px solid ${colors.lightRed};
      color: ${colors.lightRed};
      box-sizing: border-box;
      padding: 0.25rem 1rem;
      border-radius: 0.313rem;
      outline: none;
      margin-right: 0.5rem;
      font-weight: bold;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.65rem;
      }
    }
    .cancel-policy-btn:hover {
      background-color: ${colors.lightGrey};
    }

    .submit-btn {
      display: flex;
      align-items: center;
      border: 1px solid ${colors.darkerGreen};
      background-color: ${colors.green};
      color: ${colors.white};
      font-weight: bold;

      @media screen and (max-width: 576px) {
        margin-right: 1.1rem;
      }


      span {
        font-size:0.9rem;
        margin-right: 0.2rem;
      }
    }

    .submit-btn:hover {
      background-color: ${colors.darkerGreen};
    }

    .cancel-btn {
      border: 1px solid ${colors.red};
      background-color: ${colors.darkerRed};
      color: ${colors.white};
      font-weight: bold;
    }
    .cancel-btn:hover {
      background-color: ${colors.red};
    }

    .report-ready-btn {
      font-size: 0.875rem;
      color: #4A4A4A;
      border: 1px solid ${colors.lightGrey};
      background-color: #7180965e;
      box-sizing: border-box;
      padding: 0.25rem 1rem;
      border-radius: 0.313rem;
      outline: none;
      margin-right: 0.5rem;
      font-weight: bold;

      @media screen and (max-width: 576px) {
        float: unset;
        display: block;
        margin: 15px 20px 0 auto;
      }
    }
    .report-ready-btn:hover {
      background-color: ${colors.grey}
    }
  }

  .check-icon {
    height: 1.5rem;
    fill: ${colors.green};
    margin-right: 0.25rem;
  }

  .cancel-icon {
    height: 1.5rem;
    fill: ${colors.red};
    margin-right: 0.25rem;
  }
`;

export default connect(null, mapDispatchToProps)(BookingInformation);
