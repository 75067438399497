// Libs
import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { css } from "emotion";
import { useHistory } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

// Actions
import { signInEmployee } from "../../actions/authActions";
import { showModal } from "../../actions/uiActions";

// Components
import { ClipLoader } from "react-spinners";
import PageContainer from "../ui/PageContainer";

// Styles
import colors from "../../styles/colors";
import { breakPoint } from "../../styles/breakPoints";

const EmployeeLogin = (props) => {
  useEffect(() => {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
    ReactPixel.revokeConsent();
  }, []);

  const { spinner } = useSelector((state) => state.ui);
  const history = useHistory();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    setIsIOS(/iPhone|iPad|iPod/i.test(userAgent)); // Detect iOS devices
  }, []);
  
  const responseFacebook = (response) => {
    ReactPixel.grantConsent();
    props.signIn({ fbToken: response.accessToken });
  };

  const responseApple = (response) => {
    if (!response || !response.authorization) {
      console.error("Invalid Apple Sign-In response", response);
      return;
    }
    console.log("Apple Sign-In:", response);

    // Extract name and email (only available on first login)      
//    const appleToken = response.authorization.id_token;

    const { id_token, code } = response.authorization;
    const user = response.user || {}; // Contains email & name on first login
  
    // Send Apple credentials to the backend for verification
    props.signIn({
      appleToken: id_token,
      authCode: code, // Needed for token exchange in OAuth flow
      user, // Contains email & name (only on first sign-in)
    });
  };

  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle()}>
        <p className="title">Log ind</p>

        {!spinner ? (
          <>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,link,email,picture.width(500)"
              scope="public_profile,email,user_link"
              isMobile={false}
              callback={responseFacebook}
              render={(renderProps) => (
                <button
                  disabled={!acceptedTerms}
                  onClick={acceptedTerms && renderProps.onClick}
                  className={acceptedTerms ? "facebook" : "facebook disabled"}
                >
                  Fortsæt med Facebook
                </button>
              )}
            />

            {/* Only show Apple Sign-In on iOS */}
            {isIOS && (
            <AppleLogin
              clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
              redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URI}
              responseType={"code id_token"}
              scope={"name email"}
              responseMode={"query"}
              usePopup={true}
              designProp={{ locale: "da_DK" }}
              callback={responseApple}
              render={(renderProps) => (
                <button
                  disabled={!acceptedTerms}
                  onClick={acceptedTerms && renderProps.onClick}
                  className={acceptedTerms ? "apple" : "apple disabled"}
                >
                  Fortsæt med Apple
                </button>
              )}
            />
          )}
          </>
        ) : (
          <ClipLoader size={38} color={"#123abc"} loading={spinner} />
        )}

        <div className="checkbox-wrapper">
          <div className="check-box-box">
            <input
              checked={acceptedTerms}
              type="checkbox"
              onChange={() => setAcceptedTerms(!acceptedTerms)}
            />
          </div>
          <p>
            Jeg har læst og accepteret{" "}
            <a
              target="_blank"
              href={"https://www.tjenerteamet.dk/cookiepolicy.html"}
            >
              Politikken for beskyttelse af personlige oplysninger
            </a>
          </p>
        </div>
        <button
          onClick={() => history.push("/corporationLogin")}
          className="navigate-to-corporation"
        >
          Søger du personale til event? - klik her!
        </button>
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  p.title {
    color: ${colors.medDarkGrey};
    margin-top: 1rem;
    font-size: 2rem;
  }

  button.facebook,
  button.apple {
    cursor: pointer;
    margin-top: 1rem;
    color: ${colors.white};
    font-size: 1.25rem;
    padding: 0.35rem 0.75rem;
    border-radius: 5px;
    background-color: ${colors.blue};
    margin-right: 0.5rem;

    &.disabled {
      background-color: ${colors.darkGrey};
      cursor: not-allowed;
    }
  }

  div.checkbox-wrapper {
    display: flex;
    margin-top: 1.5rem;
    align-items: center;

    .check-box-box {
      display: flex;
      margin-right: 0.5rem;
      border-radius: 5px;
      background-color: ${colors.blue};
      padding: 0.2rem;

      input {
        height: 1rem;
        width: 1rem;
      }
    }

    a {
      color: ${colors.blue};
    }
  }

  button.navigate-to-corporation {
    margin-top: 1rem;
    color: ${colors.blue};
    font-weight: 500;
  }

  @media screen and (max-width: ${breakPoint.sm}px) {
    padding: 1rem;

    p.title {
      font-size: 1.5rem;
    }

    button.facebook,
    button.apple {
      font-size: 1.25rem;
    }

    div.checkbox-wrapper {
      p {
        font-size: 0.65rem;
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModal: bindActionCreators(showModal, dispatch),
  signIn: bindActionCreators(signInEmployee, dispatch),
});

export default connect(null, mapDispatchToProps)(EmployeeLogin);
