// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import Logo from "../../ui/Logo";
import CorporationNameAndRating from "../../ui/CorporationNameAndRating";
import DateViewer from "../../ui/DateViewer";
import CorporationBookingOverview from "../../ui/CorporationBookingOverview";

// Utilities
import { isBookingOpen } from "../../../utilities/check-booking-open";

// Styles
import moneyBagSVG from "../../../styles/icons/money-bag.svg";
import colors from "../../../styles/colors-V-1.0";
import { breakPoint } from "../../../styles/breakPoints";
import Label from "../../ui/Label";
import { addCommas } from "../../../utilities/add_commas";
import { remove_commas } from "../../../utilities/remove_commas";
import { workHours } from "../../../utilities/get-work-hours-total";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";

// Action
import { addToast } from "../../../actions/uiActions";

const UpcomingOverviewEmployee = (props) => {
  const { booking,employee_checkin } = props.shift;

  const [remainingTime, setRemainingTime] = useState(false)
  const [checkBookingOpen , setCheckBookingOpen] = useState(null)

  const bookingDate = moment(booking?.date); // create a new moment object from the date variable
  const twoDaysBefore = bookingDate
    .subtract(2, "days")
    .startOf("day")
    .add(6, "hours");
  const openDate = twoDaysBefore.format("DD/MM");
  const openTime = twoDaysBefore.format("HH:mm");

  const calculateRemainingTime = () => {
    let currentDate = moment().toISOString();

    return currentDate >= twoDaysBefore.toISOString() ? true : false
  };

  useEffect(() => {
    setRemainingTime(calculateRemainingTime())
    setCheckBookingOpen(isBookingOpen(booking?.date,booking?.start_time))
  },[])

  return (
    <div
      onClick={() => props.showBooking({ bookingId: booking.id })}
      style={props.style && props.style}
      className={`${componentStyle(props)} ${
        props.className ? props.className : ""
      }`}
    >
      {/** 1. section **/}
      <div className="first-wrapper">
        <div className="logo-wrapper">
          <Logo
            className="logo"
            size={50}
            src={
              booking.corporation.baseURL_image &&
              booking.corporation.icon_image &&
              `${booking.corporation.baseURL_image}h_100,w_100/dpr_2.0/r_max/${booking.corporation.icon_image}`
            }
          />
        </div>
        <CorporationNameAndRating
          className="corporation-name-and-rating"
          name={booking.corporation.name}
          numberOfRatings={booking.corporation.rating.nrOfRatings}
          value={booking.corporation.rating.rating}
        />
      </div>
      {/** 2. section **/}
      <div className="second-wrapper" >
        <div className="date-wrapper">
          <DateViewer className="date-style" size={22} date={booking.date} />
        </div>
        <div className="info-wrapper">
          <CorporationBookingOverview
            arrangementType={booking.arrangement_type || booking?.arrangement_type_other}
            address={`${booking.address}, ${booking.zip_code} ${booking.city} `}
            time={`Kl. ${booking.start_time} - ${booking.end_time}`}
            price={`DKK  ${addCommas(
              remove_commas(booking.hourly_wage) *
                workHours({
                  start_time: booking.start_time,
                  end_time: booking.end_time,
                })
            )}  (${booking.hourly_wage} /t)`}
            staffType={booking.jobRole.job_title}
            priceIcon={moneyBagSVG}
          />
        </div>
      </div>
      {/** 3. section **/}
      <div className="third-wrapper">
        <div className="space-filler" />
        <Label className="label waiting" value={"GODKENDT"} />
        {employee_checkin ? 
          <div className="employee-checkin">
          <p>KLAR TIL JOB</p>
          <CheckCircleIcon/>
        </div>
        :
        <div className="badge-container"> 
          <label
            className={`${
              remainingTime ? "open-stamp-btn" : "stamp-btn"
            }`}
          >
            {`${
              !checkBookingOpen? "TILMELD LUK" : remainingTime
                ?  "MELD KLAR - klik her! "
                : "MELD KLAR"
            }`}
          </label>
          {remainingTime && checkBookingOpen && <span className="badge" >!</span>}
        </div>
        }
        {!remainingTime && (
          <Label
            style={{ marginTop: "5px" }}
            value={`[åbner ${openDate} kl.${openTime}]`}
          />
        )}
      </div>
    </div>
  );
};

const componentStyle = (props) => css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    border: 1px solid ${colors.grey_10};
  }

  div.first-wrapper {
    display: flex;

    .logo-wrapper {
      width: 4.5rem;
      display: flex;
      justify-content: start;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
      }

      .logo {
        @media screen and (max-width: ${breakPoint.sm}px) {
          height: ${props.size - 15 || 30}px;
          width: ${props.size - 15 || 30}px;
        }
      }
    }

    .corporation-name-and-rating {
      display: flex;
      justify-content: center;
    }
  }

  div.second-wrapper {
    display: flex;

    .date-wrapper {
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      width: 4.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
      }

      .date-style {
        .day,
        .month {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 20 || 12}px;
          }
        }

        .date {
          @media screen and (max-width: ${breakPoint.sm}px) {
            font-size: ${props.size - 17 || 25}px;
          }
        }
      }
    }

    .info-wrapper {
      flex: 1;
      background-color: ${colors.white};
      border-radius: 3px;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
    }
  }

  div.third-wrapper {
    display: flex;
    margin-top: 0.25rem;

    @media screen and (max-width: 576px) {
      align-items: center;
    }

    .space-filler {
      width: 4.5rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 2.25rem;
        min-width: 2.25rem;
      }
    }

    .stamp-btn {
      color: #2e2e2e;
      font-size: 0.813rem;
      font-weight: bold;
      border-radius: 3px;
      padding: 0.25rem 1.25rem;
      border: 1px solid #b8af36;
      background-color: #f6e61c;
      margin: 0 4px 0 4px;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.4rem;
      }
    }

    .open-stamp-btn {
      color: #417505;
      font-size: 0.813rem;
      font-weight: bold;
      border-radius: 3px;
      padding: 0.25rem 1.25rem;
      border: 1px solid #417505;
      background-color: #ffffff;
      margin: 0 4px 0 4px;
      position: relative;
      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.4rem;
      }
    }

    .employee-checkin{
      display: inline-flex;
      align-items: center;
      background-color: #417505;
      color: #ffffff;
      font-size: 0.65rem;
      font-weight: bold;
      border-radius: 3px;
      padding: 0.25rem 1.25rem;
      border: 1px solid #417505;

      svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
      }
      
      // margin: 0 4px 0 4px;
      // position: relative;
      // @media screen and (max-width: ${breakPoint.sm}px) {
      //   font-size: 0.4rem;
      // }
    }


    .badge-container {
      position: relative;
      @media screen and (max-width: 576px) {
        .open-stamp-btn {
          vertical-align: text-bottom; 
        }
      }
    }
    .badge {
      width: 20px;
      height: 20px;
      top: -8px;
      right: -8px;
      cursor: auto;
      z-index: 111;
      @media screen and (max-width: 576px) {
        width: 14px;
        height: 14px;
        top: -5px;
        right: -5px;
        font-size: 11px;
      }
    }

    .label {
      font-weight: bold;
      padding: 0.25rem 1.25rem;
      font-size: 0.65rem;
      border-radius: 3px;
      border: 1px solid ${colors.green_50};
      display: flex;
      align-items: center;
      margin: 0 4px 0 4px;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 0.4rem;
      }
    }

    .waiting {
      background-color: ${colors.white};
      border-radius: 3px;
      color: ${colors.green_50};
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
});

// export default UpcomingOverviewEmployee;
export default connect(null, mapDispatchToProps)(UpcomingOverviewEmployee);
